import { FC, useLayoutEffect } from 'react';
import { animated, config, useSpring } from 'react-spring';

import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';
import { TParsedProductInstance, TProductCategory } from '@lib/core/products/types';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import Button from '@components/web/src/atoms/Buttons/Button';
import ScanCTA from '@components/web/src/atoms/ScanCTA/ScanCTA';
import { AnonymousCards } from '@components/web/src/kiosk/Cards/AnonymousCards/AnonymousCards';
import { TasteTestCards } from '@components/web/src/kiosk/Cards/TasteTestCards/TasteTestCards';
import { UserTasteCards } from '@components/web/src/kiosk/Cards/UserTasteCards/UserTasteCards';
import { KioskCirclesBackground } from '@components/web/src/kiosk/KioskCirclesBackground/KioskCirclesBackground';
import HeroSection from '@components/web/src/organisms/HeroSection/HeroSection';
import * as S from '@components/web/src/templates/Start&HomePages/Kiosk/HomePage/styles';

interface IProps {
  retailerPicture: string;
  productCategory: TProductCategory;
  productQuantity: number;
  userBestMatchCharacterForCurrentServiceProductCategory?: IParsedCharacter;
  retailerFidelityCardImage: string;
  isLoadingData: boolean;
  mobileKioskQRUrl: string;
  tipParsedProductInstanceData: TParsedProductInstance;
  wishlistQuantity: number;
  isPromotionProductsAvailable: boolean;
  wishlistParsedProductInstanceData: TParsedProductInstance;
  showAvailableFidelityCardScanView: boolean;
  showFidelityCardScannedView: boolean;
  showAnonymousView: boolean;
  onAnonymousCardClick: () => void;
  onPromotionCardClick: () => void;
  navigateToProductCatalogPage: (isEnableWishlistFilter?: boolean) => void;
  navigateAgeAndGenderTest: () => void;
  navigateToSituationalTest: () => void;
  navigateToProductPage: (productId: string) => void;
}

export const HomePage: FC<IProps> = ({
  productCategory,
  productQuantity,
  retailerPicture,
  userBestMatchCharacterForCurrentServiceProductCategory,
  retailerFidelityCardImage,
  mobileKioskQRUrl,
  tipParsedProductInstanceData,
  isPromotionProductsAvailable,
  wishlistQuantity,
  wishlistParsedProductInstanceData,
  isLoadingData,
  showAvailableFidelityCardScanView,
  showFidelityCardScannedView,
  showAnonymousView,
  navigateToProductPage,
  onAnonymousCardClick,
  onPromotionCardClick,
  navigateToProductCatalogPage,
  navigateAgeAndGenderTest,
  navigateToSituationalTest,
}) => {
  const { commonMessages, productCategories } = localeCommon;
  const { subTitleMobile, titleMobile, subTitleScan, titleScan } = localeKiosk.homeQRSection;
  const productCategoryText = LocaleUtils.publishedTerms[productCategories[productCategory]?.id];

  useLayoutEffect(() => {
    document.querySelector('#root-element')?.scrollTo(0, 0);
  }, [showFidelityCardScannedView]);

  const cardsAnimation = useSpring({
    config: config.slow,
    opacity: showAnonymousView || showFidelityCardScannedView ? 1 : 0,
  });

  return (
    <S.HomePageContainer direction="column" gap={0}>
      <KioskCirclesBackground zIndex={3} />
      <HeroSection
        isSmallVariant={showFidelityCardScannedView}
        productCategory={productCategory}
        productQuantity={productQuantity}
        retailerBackgroundImage={retailerPicture}
      />
      <S.ContentWrapper direction="column" gap={80}>
        {showAvailableFidelityCardScanView ? (
          <AnonymousCards handleClick={onAnonymousCardClick} retailerFidelityCardImage={retailerFidelityCardImage} />
        ) : (
          <>
            {showAnonymousView && (
              <animated.div style={cardsAnimation}>
                <TasteTestCards
                  navigateAgeAndGenderTest={navigateAgeAndGenderTest}
                  navigateToSituationalTest={navigateToSituationalTest}
                  productCategory={productCategory}
                />
              </animated.div>
            )}
            {showFidelityCardScannedView && (
              <UserTasteCards
                isLoading={isLoadingData}
                isPromotionProductsAvailable={isPromotionProductsAvailable}
                navigateAgeAndGenderTest={navigateAgeAndGenderTest}
                navigateToProductCatalogPage={navigateToProductCatalogPage}
                navigateToProductPage={navigateToProductPage}
                navigateToSituationalTest={navigateToSituationalTest}
                productCategory={productCategory}
                tipParsedProductInstanceData={tipParsedProductInstanceData}
                wishlistParsedProductInstanceData={wishlistParsedProductInstanceData}
                wishlistQuantity={wishlistQuantity}
                userBestMatchCharacterForCurrentServiceProductCategory={
                  userBestMatchCharacterForCurrentServiceProductCategory
                }
                onPromotionCardClick={onPromotionCardClick}
              />
            )}
            <Button
              localeOptions={{ productCategory, productCategoryText }}
              size="lg"
              text={commonMessages.catalog}
              textDecoration="underline"
              variant={VH_VARIANTS.LINK}
              onClick={() => {
                MixpanelTracker.events.productCatalogClick();
                navigateToProductCatalogPage();
              }}
            />
            <S.QrSectionWrapper style={cardsAnimation}>
              <ScanCTA
                description={subTitleMobile}
                orientation="left"
                productCategory={productCategory}
                qrCodeUrl={mobileKioskQRUrl}
                title={titleMobile}
              />
              <ScanCTA
                arrow
                description={subTitleScan}
                icon="product"
                orientation="right"
                productCategory={productCategory}
                title={titleScan}
              />
            </S.QrSectionWrapper>
          </>
        )}
      </S.ContentWrapper>
    </S.HomePageContainer>
  );
};
