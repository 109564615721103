import styled, { css } from 'styled-components';

import { DEFAULT_COLOR_THEME, STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { TYPOGRAPHY_SIZES } from '@components/web/src/foundations/Text/Text.styles';

export const HeroSectionContainer = styled.div<{ $isSmallVariant }>`
  position: relative;
  z-index: 2;
  width: 100%;
  margin: 0 auto;
  padding: 32px 64px 0;

  ${({ $isSmallVariant }) =>
    !$isSmallVariant &&
    css`
      height: 608px;
      padding: 167px 64px 142px;
    `};

  .kiosk_start_page-footer-wave {
    position: absolute;
    right: 0;
    bottom: -50px;
    left: 0;
    z-index: 1;
    margin-bottom: 24px;

    path:nth-child(1) {
      fill: ${STATIC_COLORS.base.white} !important;
      opacity: 0.6;
    }

    path:nth-child(2) {
      fill: ${({ theme }) =>
        theme?.colors?.secondaryColor['-100'] || DEFAULT_COLOR_THEME.secondaryColor['-100']} !important;
    }
  }
`;

export const Background = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: ${STATIC_COLORS.teal['900']};
    opacity: 0.5;
  }
`;

export const TextWrapper = styled(Flexbox)<{ $isSmallVariant: boolean }>`
  position: relative;
  z-index: 3;

  span {
    color: ${({ $isSmallVariant }) => ($isSmallVariant ? STATIC_COLORS.base.black : STATIC_COLORS.base.white)};

    ${({ $isSmallVariant }) =>
      !$isSmallVariant &&
      css`
        text-shadow:
          0px 4px 8px rgba(16, 24, 40, 0.1),
          0px 2px 4px rgba(16, 24, 40, 0.06);
      `}
  }
`;

export const ProductQuantityWrapper = styled(Flexbox)`
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: ${TYPOGRAPHY_SIZES.h6.xl['font-size']};
  line-height: ${TYPOGRAPHY_SIZES.h6.xl['line-height']};
  letter-spacing: ${TYPOGRAPHY_SIZES.h6.xl?.['letter-spacing'] || 'normal'};
  height: 28px;
`;
