import { FC } from 'react';

import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';
import { TParsedProductInstance, TProductCategory } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';
import { LocaleUtils } from '@lib/tools/locale/utils';

import Button from '@components/web/src/atoms/Buttons/Button';
import ScanCTA from '@components/web/src/atoms/ScanCTA/ScanCTA';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { KioskCirclesBackground } from '@components/web/src/kiosk/KioskCirclesBackground/KioskCirclesBackground';
import NoProductsAvailable from '@components/web/src/organisms/Catalog/NoProductsAvailable/NoProductsAvailable';
import { GroupedProducts } from '@components/web/src/organisms/GroupedProducts/GroupedProducts';
import KioskCharacterDetailsComponent from '@components/web/src/organisms/KioskCharacterDetails/KioskCharacterDetails';
import * as S from '@components/web/src/templates/ResultPages/Kiosk/ResultPage/styles';

interface IProps {
  productCategory: TProductCategory;
  userBestMatchCharacterForCurrentServiceProductCategory: IParsedCharacter;
  products: TParsedProductInstance[];
  isProductsLoading: boolean;
  isEnableKioskFidelityScanAddon: boolean;
  isFidelityCardScanned: boolean;
  storeType: TRetailerLocationStoreType;
  retailerFidelityCardImage: string;
  shouldHideWishlist?: boolean;
  shouldHideRatingButton?: boolean;
  shouldHideShare?: boolean;
  navigateToProductCatalogPage: () => void;
}

export const ResultPage: FC<IProps> = ({
  productCategory,
  isEnableKioskFidelityScanAddon,
  isFidelityCardScanned,
  retailerFidelityCardImage,
  products,
  isProductsLoading,
  storeType,
  shouldHideWishlist,
  shouldHideRatingButton,
  shouldHideShare,
  userBestMatchCharacterForCurrentServiceProductCategory,
  navigateToProductCatalogPage,
}) => {
  const { scanDescription, scanTitle, btn } = localeKiosk.resultPage;
  const { productCategoriesPlural } = localeCommon;
  const productCategoryPluralText = LocaleUtils.publishedTerms[productCategoriesPlural[productCategory].id];

  const { characterName } = userBestMatchCharacterForCurrentServiceProductCategory || {};
  const showScanSection = isEnableKioskFidelityScanAddon && !isFidelityCardScanned;
  const showProductsSection = products?.length > 0;

  return (
    <S.ResultPageContainer isFullHeight direction="column" gap={0}>
      <KioskCirclesBackground />
      {userBestMatchCharacterForCurrentServiceProductCategory && (
        <KioskCharacterDetailsComponent {...userBestMatchCharacterForCurrentServiceProductCategory} />
      )}
      <Flexbox direction="column" gap={0} padding="40px 32px">
        {showProductsSection ? (
          <GroupedProducts
            characterData={userBestMatchCharacterForCurrentServiceProductCategory}
            handleButtonClick={navigateToProductCatalogPage}
            isProductsLoading={isProductsLoading}
            productCategory={productCategory}
            products={products}
            shouldHideRatingButton={shouldHideRatingButton}
            shouldHideShare={shouldHideShare}
            shouldHideWishlist={shouldHideWishlist}
            storeType={storeType}
          />
        ) : (
          <Flexbox align="center" direction="column" gap={8} justify="center">
            <Button
              className="button"
              handleClick={navigateToProductCatalogPage}
              localeOptions={{ characterName, productCategory, productCategoryPluralText }}
              size="md"
              text={btn}
            />
            <NoProductsAvailable />
          </Flexbox>
        )}
      </Flexbox>
      {showScanSection && (
        <S.ScanSectionWrapper>
          <ScanCTA
            arrow
            description={scanDescription}
            icon="fidelityCard"
            orientation="right"
            productCategory={productCategory}
            retailerFidelityCardImage={retailerFidelityCardImage}
            title={scanTitle}
          />
        </S.ScanSectionWrapper>
      )}
    </S.ResultPageContainer>
  );
};
