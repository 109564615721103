import { useSelector } from 'react-redux';

import {
  selectCurrentProductTipData,
  selectIsCurrentProductTipLoading,
  selectUsersProductTipsStorage,
} from '@lib/tools/productTip/selectors';

export const useProductTip = () => ({
  currentProductTipData: useSelector(selectCurrentProductTipData),
  isCurrentProductTipLoading: useSelector(selectIsCurrentProductTipLoading),
  usersProductTipsStorage: useSelector(selectUsersProductTipsStorage),
});
