import { FC, ReactNode } from 'react';

import { TLanguage } from '@lib/core/retailers/types';

import { FooterKiosk } from '@components/web/src/atoms/Footers';
import * as S from '@components/web/src/atoms/HeaderFooter/HeaderFooterKiosk/styles';
import { HeaderKiosk } from '@components/web/src/atoms/Headers/HeaderKiosk/HeaderKiosk';

interface IProps {
  children: ReactNode;
  isHomePage: boolean;
  isShowDetachFidelityCard: boolean;
  isShowLanguageSwitcher: boolean;
  retailerLanguages: TLanguage[];
  locale: string;
  shouldHideHeader: boolean;
  shouldHideFooter: boolean;
  handleLogoClick: () => void;
  handleDetachButtonClick: () => void;
}

const HeaderFooterKiosk: FC<IProps> = ({
  children,
  isHomePage,
  isShowDetachFidelityCard,
  isShowLanguageSwitcher,
  retailerLanguages,
  locale,
  shouldHideHeader,
  shouldHideFooter,
  handleLogoClick,
  handleDetachButtonClick,
}) => (
  <S.HeaderFooterKioskContainer id="root-scroll-element">
    {!shouldHideHeader && (
      <HeaderKiosk
        handleDetachButtonClick={handleDetachButtonClick}
        handleLogoClick={handleLogoClick}
        isShowDetachFidelityCard={isShowDetachFidelityCard}
        isShowLanguageSwitcher={isShowLanguageSwitcher}
        locale={locale}
        retailerLanguages={retailerLanguages}
      />
    )}
    <S.BodyContainer align="stretch" direction="column">
      {children}
    </S.BodyContainer>
    {!shouldHideFooter && <FooterKiosk isWaveVariant={isHomePage} />}
  </S.HeaderFooterKioskContainer>
);

export default HeaderFooterKiosk;
