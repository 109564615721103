import { styled } from 'styled-components';

import { SCREEN_SIZE, STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const SituationalResultPageContainer = styled(Flexbox)`
  position: relative;
  width: ${SCREEN_SIZE.desktopKiosk};
  background-color: var(--color-secondary-100);
`;

export const HeaderWrapper = styled(Flexbox)`
  background-color: ${STATIC_COLORS.base.white};
  box-shadow: ${STATIC_SHADOWS.xl};
`;
