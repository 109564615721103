import { FC, useState } from 'react';

import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';
import { VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import activateLoyaltyCardIcon from '@components/web/src/assets/icons/default_fidelity_card_icon.png';
import anonymousCardIcon from '@components/web/src/assets/legacy/b2b/kiosk/anonymous_card_icon.svg';
import { ReactComponent as InfoIcon } from '@components/web/src/assets/legacy/b2b/kiosk/icons/info_modal_icon.svg';
import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/kiosk/Cards/AnonymousCards/styles';

interface IProps {
  retailerFidelityCardImage?: string;
  handleClick: () => void;
}

export const AnonymousCards: FC<IProps> = ({ retailerFidelityCardImage, handleClick }) => {
  const {
    anonymousCard: { title: anonymousTitle, topDescription, bottomDescription, btn },
    activateLoyaltyCard: { modalTitle, modalDescription, cta, description, title },
  } = localeKiosk.homePageCards;

  const [isModalOpened, setIsModalOpened] = useState(false);

  const modal = (
    <S.ModalContainer>
      <Flexbox align="center" justify="space-between">
        <Text color={STATIC_COLORS.base.white} size="body2" text={modalTitle} weight="semibold" />
      </Flexbox>
      <Text color={STATIC_COLORS.base.white} size="body2" text={modalDescription} weight="normal" />
    </S.ModalContainer>
  );

  return (
    <S.AnonymousCardContainer>
      <S.AnonymousCard align="center" direction="column" gap={0} padding="24px 32px 8px">
        <Image alt="anonymous icon" height="200px" src={anonymousCardIcon} width="200px" />
        <Flexbox direction="column" gap={24}>
          <Text color={STATIC_COLORS.green['600']} size="h4" text={anonymousTitle} weight="semibold" />
          <Text color={STATIC_COLORS.base.black} size="h5" text={topDescription} weight="medium" />
          <Text color={STATIC_COLORS.base.black} size="h5" text={bottomDescription} weight="medium" />
          <Button
            size="lg"
            text={btn}
            variant={VH_VARIANTS.PRIMARY}
            handleClick={() => {
              MixpanelTracker.events.playAsAnonymous();
              handleClick();
            }}
          />
        </Flexbox>
      </S.AnonymousCard>
      <S.LoyaltyCardContainer align="center" direction="column" gap={0} padding="24px 32px 8px">
        <Image
          alt="loyalty icon"
          height="200px"
          src={retailerFidelityCardImage || activateLoyaltyCardIcon}
          width="200px"
        />
        <Flexbox direction="column" gap={24}>
          <Text color={STATIC_COLORS.green['600']} size="h4" text={title} weight="semibold" />
          <Text color={STATIC_COLORS.base.black} size="h5" text={description} weight="medium" />
          <S.CTAContainer>
            {isModalOpened && modal}
            <S.ArrowIcon />
            <S.CTAText color={STATIC_COLORS.teal['600']} size="h6" text={cta} weight="bold" />
            <S.ModalOpenIcon onClick={() => setIsModalOpened(!isModalOpened)}>
              <InfoIcon />
            </S.ModalOpenIcon>
          </S.CTAContainer>
        </Flexbox>
      </S.LoyaltyCardContainer>
    </S.AnonymousCardContainer>
  );
};
