import styled from 'styled-components';

import { ReactComponent as FidelityIcon } from '@components/web/src/assets/legacy/b2b/kiosk/icons/union_fidelity_icon.svg';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';

export const AnonymousCardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

export const AnonymousCard = styled(Flexbox)`
  max-width: 503px;
  background: ${STATIC_COLORS.base.white};
  border-radius: 60px 0 0 60px;
  box-shadow: 0 1px 20px rgb(16 24 40 / 15%);
`;

export const LoyaltyCardContainer = styled(Flexbox)`
  max-width: 503px;
  background: ${STATIC_COLORS.base.white};
  border-radius: 0 60px 60px 0;
  box-shadow: 0 1px 20px rgb(16 24 40 / 15%);
`;

export const CTAContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 144px;
`;

export const ArrowIcon = styled(FidelityIcon)`
  position: absolute;
  bottom: 0;
  left: 0;

  svg {
    fill: ${STATIC_COLORS.teal['600']};

    path {
      fill: ${STATIC_COLORS.teal['600']};
    }
  }
`;

export const CTAText = styled(Text)`
  width: 323px;
  margin-left: 110px;
`;

export const ModalOpenIcon = styled.button`
  position: absolute;
  top: 9px;
  right: 0;
  padding: 0;
  background-color: transparent;
  border: none;

  svg {
    path {
      stroke: ${STATIC_COLORS.teal['600']};
    }
  }
`;

export const ModalContainer = styled.div`
  position: absolute;
  bottom: 50px;
  right: 41px;
  z-index: 3;
  width: 344px;
  padding: 12px;
  background: ${STATIC_COLORS.gray['500']};
  border-radius: 8px;

  &::before {
    position: absolute;
    top: 47.5%;
    right: -5px;
    width: 12px;
    height: 12px;
    content: '';
    background: ${STATIC_COLORS.gray['500']};
    border-radius: 1px;
    transform: rotate(-45deg);
  }
`;
