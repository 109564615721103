import { FC, useEffect, useRef } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
} from '@lib/tools/shared/helpers/consts';

import defaultBeerBackground from '@components/web/src/assets/legacy/b2b/kiosk/kiosk_home_page_beer_background.jpeg';
import defaultCoffeeBackground from '@components/web/src/assets/legacy/b2b/kiosk/kiosk_home_page_coffee_background.jpeg';
import defaultWineBackground from '@components/web/src/assets/legacy/b2b/kiosk/new_kiosk_home_page_wine_background.jpg';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/HeroSection/styles';
import FooterWave from '@components/web/src/shared/Footer/FooterWave';

interface IProps {
  productQuantity: number;
  retailerBackgroundImage?: string;
  productCategory: TProductCategory;
  isSmallVariant: boolean;
}

const HeroSection: FC<IProps> = ({
  retailerBackgroundImage = '',
  productQuantity,
  productCategory,
  isSmallVariant = false,
}) => {
  const { title, subTitle, retailerTitle } = localeKiosk.heroSection;
  const { productCategoriesPlural, productCategories } = localeCommon;

  const productCategoryPluralText = productCategoriesPlural[productCategory];
  const productCategoryText = productCategories[productCategory];

  const productQuantityRef = useRef<HTMLSpanElement>(null);

  const animateValue = (element: HTMLElement, start: number, end: number, duration: number) => {
    const range = end - start;
    const startTime = performance.now();

    const updateValue = (currentTime: number) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);

      const value = start + range * progress;
      element.textContent = value >= 1 ? Math.floor(value).toString() : '';

      if (progress < 1) {
        requestAnimationFrame(updateValue);
      }
    };

    requestAnimationFrame(updateValue);
  };

  const getProductCategoryDefaultBackgroundImage = () => {
    switch (productCategory) {
      case PRODUCT_CATEGORY_WINE:
        return defaultWineBackground;
      case PRODUCT_CATEGORY_BEER:
        return defaultBeerBackground;
      case PRODUCT_CATEGORY_COFFEE:
        return defaultCoffeeBackground;
      default:
        return defaultWineBackground;
    }
  };

  useEffect(() => {
    if (productQuantityRef.current) {
      animateValue(productQuantityRef.current, 0, productQuantity, 2000);
    }
  }, [productQuantity, isSmallVariant]);

  return (
    <S.HeroSectionContainer $isSmallVariant={isSmallVariant}>
      {!isSmallVariant && (
        <>
          <S.Background
            style={{
              backgroundImage: `url('${retailerBackgroundImage || getProductCategoryDefaultBackgroundImage()}')`,
            }}
          />
          <FooterWave className="kiosk_start_page-footer-wave" />
        </>
      )}
      <S.TextWrapper $isSmallVariant={isSmallVariant} direction="column" gap={8}>
        <S.ProductQuantityWrapper align="center" gap={4}>
          {!!productQuantity && (
            <>
              <span ref={productQuantityRef} />
              <Text size="h6" text={productCategoryPluralText} textTransform="lowercase" weight="medium" />
            </>
          )}
        </S.ProductQuantityWrapper>
        <Text
          fontFamily={isSmallVariant ? 'Fraunces' : 'Montserrat'}
          localeIndex={{ productCategory }}
          localeVariables={{ productCategoryText }}
          size={isSmallVariant ? 'h2' : 'h1'}
          text={isSmallVariant ? retailerTitle : title}
          weight={isSmallVariant ? 'semibold' : 'bold'}
        />
        {!isSmallVariant && <Text size="h4" text={subTitle} weight="medium" />}
      </S.TextWrapper>
    </S.HeroSectionContainer>
  );
};

export default HeroSection;
