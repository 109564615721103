import { FC } from 'react';

import { TProductCategory } from '@lib/core/products/types';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';
import { getProductCategoryTasteIcon } from '@lib/tools/shared/helpers';
import { PRODUCT_CATEGORY_WINE, VH_VARIANTS } from '@lib/tools/shared/helpers/consts';

import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import { HomeRecipeCard } from '@components/web/src/kiosk/Cards/HomeRecipeCard/HomeRecipeCard';
import * as S from '@components/web/src/kiosk/Cards/TasteTestCards/styles';

export type Props = {
  productCategory: TProductCategory;
  navigateAgeAndGenderTest: () => void;
  navigateToSituationalTest: () => void;
};

export const TasteTestCards: FC<Props> = ({
  productCategory = PRODUCT_CATEGORY_WINE,
  navigateAgeAndGenderTest,
  navigateToSituationalTest,
}) => {
  const { tasteTestCard } = localeKiosk.homePageCards;
  const { productCategories } = localeCommon;

  return (
    <Flexbox gap={10} justify="center">
      <S.TasteCardContainer direction="column" gap={16} justify="space-between" padding="24px 32px 32px">
        <Flexbox align="center" direction="column-reverse" gap={0}>
          <Text
            color={STATIC_COLORS.gray[900]}
            size="h5"
            text={tasteTestCard.description}
            weight="medium"
            localeOptions={{
              productCategory,
            }}
            localeVariables={{
              productCategoryText: productCategories[productCategory],
            }}
          />
          <Image alt="recipe" height="200px" src={getProductCategoryTasteIcon(productCategory)} width="200px" />
        </Flexbox>
        <Button
          fontSize="subtitle1"
          size="lg"
          text={tasteTestCard.btn}
          textWeight="medium"
          variant={VH_VARIANTS.PRIMARY}
          handleClick={() => {
            MixpanelTracker.events.startTasteTestClick();
            navigateAgeAndGenderTest();
          }}
        />
      </S.TasteCardContainer>

      <HomeRecipeCard
        productCategory={productCategory}
        handleClick={() => {
          MixpanelTracker.events.startSituationalTestClick();
          navigateToSituationalTest();
        }}
      />
    </Flexbox>
  );
};
