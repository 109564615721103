import { FC } from 'react';

import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';
import { TParsedProductInstance, TProductCategory } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { MP_POSITION_CONTEXT } from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';
import { LocaleUtils } from '@lib/tools/locale/utils';

import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/GroupedProducts/styles';
import ProductCard from '@components/web/src/organisms/ProductCards/OldProductCard/ProductCard';

interface IProps {
  productCategory: TProductCategory;
  products: TParsedProductInstance[];
  isProductsLoading: boolean;
  storeType: TRetailerLocationStoreType;
  shouldHideWishlist?: boolean;
  shouldHideRatingButton?: boolean;
  shouldHideShare?: boolean;
  characterData: IParsedCharacter;
  handleButtonClick: () => void;
}

export const GroupedProducts: FC<IProps> = ({
  productCategory,
  products = [],
  isProductsLoading,
  storeType,
  shouldHideRatingButton,
  shouldHideWishlist,
  shouldHideShare,
  characterData,
  handleButtonClick,
}) => {
  const { btn } = localeKiosk.resultPage;
  const { productCategoriesPlural } = localeCommon;

  const { characterName, characterTechnicalCaption } = characterData || {};

  const productCategoryPluralText = LocaleUtils.publishedTerms[productCategoriesPlural[productCategory].id];

  return (
    <S.GroupedProductsContainer isFullWidth direction="column" gap={20}>
      {characterTechnicalCaption && (
        <Text
          color={STATIC_COLORS.base.black}
          fontFamily="Fraunces"
          size="h5"
          text={characterTechnicalCaption}
          weight="semibold"
        />
      )}
      <Flexbox direction="column" gap={32}>
        <S.ProductsWrapper>
          {isProductsLoading
            ? Array.from({ length: 3 }, (_, idx) => (
                <ProductCard key={idx} isLoading={isProductsLoading} storeType={storeType} />
              ))
            : products.map((parsedProductInstanceData, idx) => (
                <ProductCard
                  key={idx}
                  isResponsive
                  isLoading={isProductsLoading}
                  mixpanelIndex={idx}
                  mixpanelPositionContext={MP_POSITION_CONTEXT.SWIPER}
                  parsedProductInstanceData={parsedProductInstanceData}
                  shouldHideRatingButton={shouldHideRatingButton}
                  shouldHideShare={shouldHideShare}
                  shouldHideWishlist={shouldHideWishlist}
                  storeType={storeType}
                />
              ))}
        </S.ProductsWrapper>
        <Button
          className="button"
          handleClick={handleButtonClick}
          localeOptions={{ characterName, productCategory, productCategoryPluralText }}
          size="md"
          text={btn}
        />
      </Flexbox>
    </S.GroupedProductsContainer>
  );
};
