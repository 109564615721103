import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useParsedProducts } from '@lib/core/products/hooks/useParsedProducts';
import { useRetailer } from '@lib/core/retailers/hooks/retailer';
import { useRetailerLocation } from '@lib/core/retailers/hooks/retailerLocation';
import { ENABLE_PROMOTION_TOGGLE_URL_PARAM, ENABLE_WISHLIST_FILTER_URL_PARAM } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useKioskRoot } from '@lib/core/service/hooks/useKioskRoot';
import { fetchHomePageProductsCount, setIsAnonymousPreference } from '@lib/core/service/slices/kioskRootSlice';
import { createMobileKioskQRUrl, getRandomItemFromArray, prependBasename } from '@lib/core/service/utils';
import { useProductList, useUser } from '@lib/core/users/hooks';
import { useFidelityCard } from '@lib/core/users/hooks/useFidelityCard';
import { usePromotionProducts } from '@lib/tools/promotionProducts/hooks/usePromotionProducts';
import { getRandomIndex } from '@lib/tools/shared/helpers';
import { PRODUCT_CATEGORY_QUERY, PRODUCT_ID_URL_PARAM } from '@lib/tools/shared/helpers/consts';
import { useAddons } from '@lib/tools/views/hooks';
import { PAGES } from '@lib/tools/views/urls';

import { HomePage } from '@components/web/src/templates/Start&HomePages/Kiosk/HomePage/HomePage';

import useProductTipData from 'hooks/useProductTipData';

const HomeContainer: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { retailerFidelityCardImage } = useRetailer();
  const { productCategory } = useApp();
  const { isEnableKioskFidelityScanAddon } = useAddons();
  const { isFidelityCardLoading, fidelityCardId } = useFidelityCard();
  const { customStyle } = useRetailerLocation();
  const { wishlistProductInstanceData, isWishlistProductListLoading } = useProductList();
  const { userBestMatchCharacterForCurrentServiceProductCategory } = useUser();
  const { currentProductTipData, isCurrentProductTipLoading } = useProductTipData();
  const { parseProduct } = useParsedProducts();
  const { isPromotionProductsAvailable, isPromotionProductsLoading } = usePromotionProducts();
  const { productQuantity, isAnonymousPreference } = useKioskRoot();
  const mobileKioskQRUrl = createMobileKioskQRUrl();

  const retailerPicture = useMemo(() => {
    const mediaList = customStyle?.[productCategory]?.homeMedia || [];
    return mediaList.length ? mediaList[getRandomIndex(mediaList.length)] : '';
  }, [customStyle?.[productCategory]?.homeMedia]);

  const { randomWishlistProductInstance, wishlistQuantity } = useMemo(() => {
    const filteredData = wishlistProductInstanceData.filter(
      productInstanceData => productInstanceData?.product?.category === productCategory,
    );

    return {
      randomWishlistProductInstance: getRandomItemFromArray(filteredData),
      wishlistQuantity: filteredData.length || 0,
    };
  }, [wishlistProductInstanceData, productCategory]);

  useEffect(() => {
    if (!productQuantity) {
      const params = {
        [PRODUCT_CATEGORY_QUERY]: productCategory,
        limit: 1,
        offset: 0,
      };
      dispatch(fetchHomePageProductsCount(params));
    }
  }, []);

  const showAvailableFidelityCardScanView =
    isEnableKioskFidelityScanAddon && !fidelityCardId && !isFidelityCardLoading && !isAnonymousPreference;
  const showFidelityCardScannedView = !!fidelityCardId || isFidelityCardLoading;
  const showAnonymousView =
    (isEnableKioskFidelityScanAddon && !fidelityCardId && !isFidelityCardLoading && isAnonymousPreference) ||
    !isEnableKioskFidelityScanAddon;

  const isLoadingData =
    isFidelityCardLoading || isWishlistProductListLoading || isCurrentProductTipLoading || isPromotionProductsLoading;

  const onAnonymousCardClick = () => dispatch(setIsAnonymousPreference(true));
  const onPromotionCardClick = () =>
    navigate(
      prependBasename(PAGES.vinhood.catalog, {
        [ENABLE_PROMOTION_TOGGLE_URL_PARAM]: true,
      }),
    );
  const navigateAgeAndGenderTest = () => navigate(prependBasename(PAGES.vinhood.ageAndGender));
  const navigateToSituationalTest = () => navigate(prependBasename(PAGES.vinhood.quiz.situational));
  const navigateToProductCatalogPage = (isEnableWishlistFilter?: boolean) =>
    navigate(
      prependBasename(PAGES.vinhood.catalog, isEnableWishlistFilter && { [ENABLE_WISHLIST_FILTER_URL_PARAM]: true }),
    );
  const navigateToProductPage = productId =>
    navigate(prependBasename(PAGES.vinhood.product, { [PRODUCT_ID_URL_PARAM]: productId }), {
      state: { from: pathname },
    });

  return (
    <HomePage
      isLoadingData={isLoadingData}
      isPromotionProductsAvailable={isPromotionProductsAvailable}
      mobileKioskQRUrl={mobileKioskQRUrl}
      navigateAgeAndGenderTest={navigateAgeAndGenderTest}
      navigateToProductCatalogPage={navigateToProductCatalogPage}
      navigateToProductPage={navigateToProductPage}
      navigateToSituationalTest={navigateToSituationalTest}
      productCategory={productCategory}
      productQuantity={productQuantity}
      retailerFidelityCardImage={retailerFidelityCardImage}
      retailerPicture={retailerPicture}
      showAnonymousView={showAnonymousView}
      showAvailableFidelityCardScanView={showAvailableFidelityCardScanView}
      showFidelityCardScannedView={showFidelityCardScannedView}
      tipParsedProductInstanceData={parseProduct(currentProductTipData)}
      userBestMatchCharacterForCurrentServiceProductCategory={userBestMatchCharacterForCurrentServiceProductCategory}
      wishlistParsedProductInstanceData={parseProduct(randomWishlistProductInstance)}
      wishlistQuantity={wishlistQuantity}
      onAnonymousCardClick={onAnonymousCardClick}
      onPromotionCardClick={onPromotionCardClick}
    />
  );
};

export default HomeContainer;
