import { animated } from 'react-spring';
import { styled } from 'styled-components';

import { SCREEN_SIZE } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const HomePageContainer = styled(Flexbox)`
  position: relative;
  width: ${SCREEN_SIZE.desktopKiosk};
  height: 100%;
  background-color: var(--color-secondary-100);
`;

export const ContentWrapper = styled(Flexbox)`
  position: relative;
  z-index: 9999;
  flex: 1;
  padding: 24px 32px;
`;

export const QrSectionWrapper = styled(animated.div)`
  position: absolute;
  bottom: -120px;
  left: 0;
  width: 100%;

  & > div:nth-of-type(2) {
    margin-top: -155px;
  }
`;
