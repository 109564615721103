import { createSelector } from '@reduxjs/toolkit';

import { IProductTip } from '@lib/tools/productTip/types/interfaces';

/**
 * Selects the entire product tip state from the Redux store.
 */
export const selectProductTipState = (state): IProductTip => state.productTip || {};

/**
 * Selects the user's product tips storage from the product tip state.
 */
export const selectUsersProductTipsStorage = createSelector(
  [selectProductTipState],
  productTip => productTip.usersProductTipsStorage,
);

/**
 * Selects the current product tip from the product tip state.
 */
export const selectCurrentProductTip = createSelector(
  [selectProductTipState],
  productTip => productTip.currentProductTip,
);

/**
 * Selects the data for the current product tip.
 */
export const selectCurrentProductTipData = createSelector(
  [selectCurrentProductTip],
  currentProductTip => currentProductTip.currentProductTipData,
);

/**
 * Selects the loading state for the current product tip.
 */
export const selectIsCurrentProductTipLoading = createSelector(
  [selectCurrentProductTip],
  currentProductTip => currentProductTip.isCurrentProductTipLoading,
);
