import { FC } from 'react';

import { TParsedProductInstance, TProductCategory } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { IThirdPartyNewsletterTexts } from '@lib/core/service/types/interface';
import { TProductCatalogOrdering } from '@lib/tools/filterManager/slices/productFilter';
import { IFilterChangeAction } from '@lib/tools/filterManager/slices/sublistFilterSlice';
import FilterContainer from '@lib/tools/filterManager/views/containers/FilterContainer';
import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';
import { LocaleFragment } from '@lib/tools/locale/views/LocaleFragment';
import { PRODUCT_CATEGORY_WINE } from '@lib/tools/shared/helpers/consts';
import { IFilterProps, IHandleProductsRequestParams } from '@lib/tools/shared/helpers/interfaces';

import { KioskCirclesBackground } from '@components/web/src/kiosk/KioskCirclesBackground/KioskCirclesBackground';
import NoProductsAvailable from '@components/web/src/organisms/Catalog/NoProductsAvailable/NoProductsAvailable';
import ProductListComponent from '@components/web/src/organisms/Catalog/ProductList/ProductList';
import SortingSection from '@components/web/src/organisms/Catalog/SortingSection/SortingSection';

interface IProps {
  products: TParsedProductInstance[];
  isProductsRequestLoading: boolean;
  productCategory: TProductCategory;
  filterProps: IFilterProps;
  itemsCount?: number;
  storeType: TRetailerLocationStoreType;
  activeOrdering: TProductCatalogOrdering;
  locale: string;
  shouldHideRatingButton?: boolean;
  shouldHideWishlist?: boolean;
  shouldHideShare?: boolean;
  isChangeOrderingEnabled?: boolean;
  thirdPartyNewsletterTexts: IThirdPartyNewsletterTexts;
  handleChangeOrdering: (order: TProductCatalogOrdering) => void;
  handleProductsRequest: (args: IHandleProductsRequestParams) => void;
  handleUpdateFilterType: (filterItem: IFilterChangeAction) => void;
}

export const ProductCatalogPage: FC<IProps> = ({
  products,
  productCategory = PRODUCT_CATEGORY_WINE,
  filterProps,
  isProductsRequestLoading,
  itemsCount,
  storeType,
  shouldHideWishlist,
  shouldHideRatingButton,
  shouldHideShare,
  activeOrdering,
  isChangeOrderingEnabled = false,
  thirdPartyNewsletterTexts,
  handleChangeOrdering,
  handleProductsRequest,
  handleUpdateFilterType,
}) => {
  const { productCatalog: productCatalogMessagesKiosk } = localeKiosk;
  return (
    <div className="product-catalog-container">
      <KioskCirclesBackground />
      <div className="products-list-title">
        <LocaleFragment message={productCatalogMessagesKiosk.productsListHeadText} />
      </div>
      <FilterContainer
        {...filterProps}
        isSearchEnabled
        handleApply={handleProductsRequest}
        handleUpdateFilterType={handleUpdateFilterType}
        productCategory={productCategory}
      />
      {!isProductsRequestLoading && products.length > 0 && (
        <SortingSection
          activeOrdering={activeOrdering}
          handleChangeOrdering={handleChangeOrdering}
          isChangeOrderingEnabled={isChangeOrderingEnabled}
          isProductsRequestLoading={isProductsRequestLoading}
          itemsCount={itemsCount}
        />
      )}
      <ProductListComponent
        handleProductsRequest={handleProductsRequest}
        isProductsRequestLoading={isProductsRequestLoading}
        itemsCount={itemsCount}
        products={products}
        shouldHideRatingButton={shouldHideRatingButton}
        shouldHideShare={shouldHideShare}
        shouldHideWishlist={shouldHideWishlist}
        storeType={storeType}
        thirdPartyNewsletterTexts={thirdPartyNewsletterTexts}
      />
      {!isProductsRequestLoading && products.length === 0 && (
        <div className="no-products-container">
          <NoProductsAvailable />
        </div>
      )}
    </div>
  );
};
