import styled from 'styled-components';

import { CloseButton } from '@components/web/src/atoms/Buttons/CloseButton/CloseButton';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Image } from '@components/web/src/foundations/Image/Image';

export const ChefRecommendedPairingsModalContainer = styled.div`
  padding: 18px 32px;
`;

export const CloseIcon = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const TitleTextWrapper = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: 22px 16px 8px;
`;

export const RecipeCard = styled.div`
  display: flex;
  gap: 32px;
  padding: 32px 0 16px;

  &:not(:last-child) {
    padding: 32px 0;
    border-bottom: 1px solid ${STATIC_COLORS.gray[300]};
  }
`;

export const RecipeCardImage = styled(Image)`
  height: 236px;
  min-width: 290px;
  min-width: 290px;
`;

export const RecipeCardTextPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-right: 8px;
  justify-content: center;
`;

export const RecipeCardTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const RecipeCardTitleImage = styled(Image)`
  height: 27px;
  max-width: fit-content;
  min-width: auto;
`;

export const StyledCloseButton = styled(CloseButton)`
  width: 24px;
  height: 24px;
`;
