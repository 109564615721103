import { css, styled } from 'styled-components';

import { TProductCategory } from '@lib/core/products/types';

import Button from '@components/web/src/atoms/Buttons/Button';
import { STATIC_COLORS, STATIC_SHADOWS } from '@components/web/src/foundations';
import { Text } from '@components/web/src/foundations/Text/Text';
import { PROPOSITION_CARD_VARIANTS } from '@components/web/src/organisms/Cards/PropositionCard/PropositionCard';

export const ProductPropositionCardContainer = styled.div<{
  $variant: keyof typeof PROPOSITION_CARD_VARIANTS;
  $productCategory: TProductCategory;
  $isNotRoundedVariant: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 503px;
  height: 100%;
  min-height: 503px;
  padding: 30px 32px;
  background-color: ${STATIC_COLORS.base.white};
  border-radius: 0 0 60px 10px;
  box-shadow: ${STATIC_SHADOWS.lg};

  ${({ $variant, $productCategory }) =>
    $variant === PROPOSITION_CARD_VARIANTS.tip &&
    css`
      background-color: ${STATIC_COLORS.productColors[$productCategory][200]};
      border-radius: 0 0 10px 60px;
    `};

  ${({ $isNotRoundedVariant }) =>
    $isNotRoundedVariant &&
    css`
      border-radius: 0;
    `};
`;

export const CardHeaderWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
`;

export const CardHeaderIcon = styled.div<{ $productCategory: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 5px;
  border-radius: 12px;

  ${({ $productCategory }) => css`
    background-color: ${STATIC_COLORS.productColors[$productCategory][500]};
  `};
`;

export const ViewAllBtn = styled(Button)`
  margin-left: auto;
  padding: 0;

  span {
    text-transform: lowercase;
    text-decoration: underline;
    color: ${STATIC_COLORS.base.black};
  }
`;

export const ProductDetailsViewWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-top: auto;
`;

export const PromoIcon = styled.div`
  position: absolute;
  top: 10px;
  left: -48px;
  z-index: 1;
  width: 140px;
  height: 20px;
  padding: 3px 0;
  background-color: #de5242;
  transform: rotate(-45deg);
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProductImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 253.58px;
  height: 240px;

  img {
    position: absolute;
    top: 20px;
    width: 190px;
    height: 190px;
    object-fit: contain;
  }
`;

export const ProductName = styled(Text)`
  min-height: 64px;
  max-height: 64px;
`;

export const ProducerName = styled(Text)`
  min-height: 24px;
  max-height: 24px;
`;

export const ProductPriceFormatContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  width: 100%;

  & > div {
    margin-left: auto;
  }
`;

export const StyledProductFormatText = styled(Text)`
  flex-grow: 1;
  flex-wrap: wrap;
`;

export const ProductPriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0 8px;
  align-items: center;
  justify-content: flex-end;
`;

export const GiveItBtn = styled(Button)<{ $variant: keyof typeof PROPOSITION_CARD_VARIANTS }>`
  margin-left: auto;
  padding: 10px 14px;

  ${({ $variant }) => css`
    color: ${$variant === PROPOSITION_CARD_VARIANTS.tip
      ? STATIC_COLORS.base.white
      : STATIC_COLORS.base.black} !important;
  `};
`;

export const NoProductDetailsViewWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  justify-content: center;
  text-align: center;
  height: 100%;
`;

export const CtaBtn = styled(Button)`
  padding: 0;

  span {
    color: ${STATIC_COLORS.base.black};
    text-decoration: underline;
  }
`;
