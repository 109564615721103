import { FC } from 'react';

import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';
import { TProductCategory } from '@lib/core/products/types';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';
import { LocaleUtils } from '@lib/tools/locale/utils';
import { getPrimaryProductCategoryColor } from '@lib/tools/shared/helpers';
import {
  PRODUCT_CATEGORY_BEER,
  PRODUCT_CATEGORY_COFFEE,
  PRODUCT_CATEGORY_WINE,
  VH_VARIANTS,
} from '@lib/tools/shared/helpers/consts';

import beerCharactersSetIcon from '@components/web/src/assets/legacy/b2b/kiosk/beer_characters_set.png';
import coffeeCharactersSetIcon from '@components/web/src/assets/legacy/b2b/kiosk/coffee_characters_set.png';
import wineCharactersSetIcon from '@components/web/src/assets/legacy/b2b/kiosk/wine_characters_set.png';
import Button from '@components/web/src/atoms/Buttons/Button';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/kiosk/Cards/CharacterTasteCard/styles';

export type Props = {
  productCategory: TProductCategory;
  characterData?: IParsedCharacter;
  navigateAgeAndGenderTest: () => void;
  navigateToProductCatalogPage: (isEnableWishlistFilter?: boolean) => void;
};

export const CharacterTasteCard: FC<Props> = ({
  productCategory = PRODUCT_CATEGORY_WINE,
  characterData,
  navigateToProductCatalogPage,
  navigateAgeAndGenderTest,
}) => {
  const { characterCaption, characterName, characterImage } = characterData || {};
  const { characterExperienceCard } = localeKiosk.homePageCards;
  const { productCategoriesPlural, productCategories } = localeCommon;

  const { publishedTerms } = LocaleUtils;

  const productCategoryText = publishedTerms[productCategories[productCategory].id];
  const productCategoryPluralText = publishedTerms[productCategoriesPlural[productCategory].id];
  const testAgainTest = (
    <S.TestAgainTest
      size="h6"
      text={characterExperienceCard.testAgain}
      insertIntlJSXPartOptions={{
        className: 'cta-test',
        linkId: 'test-page',
        linkRender: text => (
          <span aria-hidden className="decorated-text" onClick={navigateAgeAndGenderTest}>
            {text}
          </span>
        ),
      }}
    />
  );
  const primaryProductColor = getPrimaryProductCategoryColor(productCategory);

  const getProductCategoryBackgroundImage = () => {
    switch (productCategory) {
      case PRODUCT_CATEGORY_WINE:
        return wineCharactersSetIcon;
      case PRODUCT_CATEGORY_BEER:
        return beerCharactersSetIcon;
      case PRODUCT_CATEGORY_COFFEE:
        return coffeeCharactersSetIcon;
      default:
        return wineCharactersSetIcon;
    }
  };

  return (
    <S.CharacterTasteCardContainer
      style={{
        backgroundColor: primaryProductColor,
      }}
    >
      {characterName && (
        <S.CharacterBackground
          style={{
            backgroundImage: `url(${characterImage})`,
          }}
        />
      )}
      <S.CharacterCardContentWrapper>
        {characterName ? (
          <>
            <Text
              fontFamily="Fraunces"
              size="h5"
              text={characterExperienceCard.title}
              weight="semibold"
              localeIndex={{
                productCategory,
              }}
              localeOptions={{
                characterName,
                productCategoryText,
              }}
            />
            <Text size="h6" text={characterCaption} weight="semibold" />
            <Text size="h6" text={characterExperienceCard.description} />
            {testAgainTest}
          </>
        ) : (
          <>
            <S.MissedCharacterImage alt="characters" src={getProductCategoryBackgroundImage()} />
            <Text
              size="h5"
              text={characterExperienceCard.missedCharacterTitle}
              localeIndex={{
                productCategory,
              }}
              localeOptions={{
                productCategoryPluralText,
              }}
            />
            <Text size="h6" text={characterExperienceCard.missedCharacterDescription} />
          </>
        )}
      </S.CharacterCardContentWrapper>
      <Button
        fontSize="subtitle1"
        handleClick={characterName ? () => navigateToProductCatalogPage() : navigateAgeAndGenderTest}
        size="lg"
        text={characterName ? characterExperienceCard.btn : characterExperienceCard.missedCharacterBtn}
        textWeight="medium"
        variant={VH_VARIANTS.LIGHT}
        style={{
          color: primaryProductColor,
        }}
      />
    </S.CharacterTasteCardContainer>
  );
};
