import { FC, Fragment } from 'react';

import { TParsedProductInstance } from '@lib/core/products/types';
import { promotionsFilter } from '@lib/core/service/utils';
import MixpanelTracker from '@lib/tools/dat/mixpanel';
import { MP_POSITION_CONTEXT, MixpanelPositionContext } from '@lib/tools/dat/mixpanel/consts';
import { localeCommon } from '@lib/tools/locale/source/web/common';
import {
  PROMOTION_BADGE_FIDELITY,
  PROMOTION_BADGE_OTHER,
  PROMOTION_BADGE_PROMOTION,
  VH_VARIANTS,
} from '@lib/tools/shared/helpers/consts';
import { parseTasteMatchLevel } from '@lib/tools/tasteMatch';
import { useAddons } from '@lib/tools/views/hooks';

import Button from '@components/web/src/atoms/Buttons/Button';
import PriceRange from '@components/web/src/atoms/PriceRange/PriceRange';
import TasteMatchButton from '@components/web/src/atoms/TasteMatchButton/TasteMatchButton';
import Wishlist from '@components/web/src/atoms/Wishlist/Wishlist';
import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Image } from '@components/web/src/foundations/Image/Image';
import { Text } from '@components/web/src/foundations/Text/Text';
import * as S from '@components/web/src/organisms/ProductDetails/Kiosk/styles';
import { PromotionBadges } from '@components/web/src/organisms/PromotionBadges/PromotionBadges';
import FindProductPopup from '@components/web/src/shared/FindProductPopup/FindProductPopup';

interface IProps {
  parsedProductInstanceData: TParsedProductInstance;
  isShowPromoLabel?: boolean;
  shouldHideWishlist?: boolean;
  isUserHasAnyCharacter: boolean;
  mixpanelPositionContext?: MixpanelPositionContext;
  navigateToQuizSelectionPage: () => void;
}

const LargeProductDetails: FC<IProps> = ({
  parsedProductInstanceData,
  isShowPromoLabel,
  shouldHideWishlist,
  isUserHasAnyCharacter,
  mixpanelPositionContext = MP_POSITION_CONTEXT.PRODUCT_PAGE,
  navigateToQuizSelectionPage,
}) => {
  const { productDetails: productDetailsMessages, commonMessages } = localeCommon;

  const {
    productId,
    productCategory,
    productCharacterName,
    productCharacterTechnicalCaption,
    productName,
    productImage,
    productTags,
    productFormatSize,
    productPriceCurrencySymbol,
    productProducerName,
    productOriginalPrice,
    productDiscountPrice,
    productOriginalPriceAndCurrency,
    productDiscountPriceAndCurrency,
    promotions,
    productTasteMatchValue,
    isFindProductButtonEnable,
    productLocationDescription,
    productLocationMap,
    productEcommerceId,
    isProductInstanceInWishlist,
    isWishlistProductListLoading,
    handleUpdateWishlistProductList,
    identitiesForKiosk,
    isEnableLocationMapAddon,
    isEnableVusionAddon,
  } = parsedProductInstanceData || {};
  const { isPriceRangeAddon } = useAddons();
  const isProductDiscountAvailable = !!productDiscountPrice && !!productOriginalPrice;

  const promotionBadges = promotionsFilter(promotions, [
    PROMOTION_BADGE_FIDELITY,
    PROMOTION_BADGE_PROMOTION,
    PROMOTION_BADGE_OTHER,
  ]);

  return (
    <S.LargeProductDetailsContainer>
      <S.LargeProductImageContainer>
        {isShowPromoLabel && (
          <S.LargeTopPromoIcon>
            <Text color={STATIC_COLORS.base.white} size="subtitle1" text={commonMessages.promo} weight="semibold" />
          </S.LargeTopPromoIcon>
        )}
        <S.LargeProductImageWrapper>
          <Image alt="product" height="85%" objectFit="contain" src={productImage} />
        </S.LargeProductImageWrapper>
        <S.WishListCtaWrapper align="center" direction="column" gap={16}>
          {!shouldHideWishlist && (
            <Wishlist
              isItemInWishlist={isProductInstanceInWishlist}
              isItemListLoading={isWishlistProductListLoading}
              size="large"
              handleUpdateWishlist={() => {
                handleUpdateWishlistProductList(productId, productName);
                MixpanelTracker.events.productBookmark(parsedProductInstanceData, null, mixpanelPositionContext);
              }}
            />
          )}
        </S.WishListCtaWrapper>
      </S.LargeProductImageContainer>
      <S.LargeProductContentContainer>
        <S.FirstSection>
          <Text
            color={STATIC_COLORS.base.black}
            fontFamily="Fraunces"
            linesLimit={3}
            size="h3"
            text={productName}
            weight="semibold"
          />
          {productProducerName && (
            <Text color={STATIC_COLORS.base.black} size="h6" text={productProducerName} weight="medium" />
          )}
          <S.LargeTagsWrapper>
            {productTags?.map((tag, index) => (
              <Image key={index} alt={tag.tagName} height="44px" src={tag.icon} width="44px" />
            ))}
          </S.LargeTagsWrapper>
        </S.FirstSection>
        <S.CharacterBlock>
          <Text color={STATIC_COLORS.base.black} size="subtitle2" text={`#${productCharacterName}`} weight="semibold" />{' '}
          <Text color={STATIC_COLORS.base.black} size="subtitle2" text={`• ${productCharacterTechnicalCaption}`} />
        </S.CharacterBlock>

        <TasteMatchButton
          hideTooltip={() => {}}
          navigateToQuizSelectionPage={navigateToQuizSelectionPage}
          parsedProductInstanceData={parsedProductInstanceData}
          tasteMatchLevel={parseTasteMatchLevel(productTasteMatchValue, isUserHasAnyCharacter)}
          tasteMatchValue={productTasteMatchValue}
        />

        <S.LargePriceAndFormatWrapper>
          {isPriceRangeAddon ? (
            <PriceRange
              isLargeFont
              currencySymbolValue={productPriceCurrencySymbol}
              originalPrice={productOriginalPrice}
              productCategory={productCategory}
            />
          ) : (
            <Flexbox align="center" gap={8}>
              {isProductDiscountAvailable && (
                <Text
                  color={STATIC_COLORS.gray[700]}
                  fontFamily="Fraunces"
                  size="h5"
                  text={productOriginalPriceAndCurrency}
                  textDecoration="line-through"
                />
              )}

              {(isProductDiscountAvailable || !!productOriginalPrice) && (
                <Text
                  color={STATIC_COLORS.base.black}
                  fontFamily="Fraunces"
                  size="h4"
                  text={isProductDiscountAvailable ? productDiscountPriceAndCurrency : productOriginalPriceAndCurrency}
                  weight="semibold"
                />
              )}

              {productFormatSize && (
                <Text
                  color={STATIC_COLORS.gray[700]}
                  size="h6"
                  text={`${productOriginalPrice ? '/ ' : ''}${productFormatSize}`}
                />
              )}
            </Flexbox>
          )}
        </S.LargePriceAndFormatWrapper>
        <S.IdentitiesWrapper $rows={identitiesForKiosk.length}>
          {identitiesForKiosk.map(({ icon, title, description }, index) => (
            <Fragment key={index}>
              <S.IdentityImage alt="icon" src={icon} />
              <S.IdentityTitle>
                <Text size="subtitle2" text={title} />:
              </S.IdentityTitle>

              <Text size="subtitle2" text={description} weight="semibold" />
            </Fragment>
          ))}
        </S.IdentitiesWrapper>
        {!!promotionBadges?.length && <PromotionBadges promotionsData={promotionBadges} variant="large" />}
        {isFindProductButtonEnable && (
          <S.LargeCtaWrapper>
            <FindProductPopup
              isEnableLocationMapAddon={isEnableLocationMapAddon}
              isEnableVusionAddon={isEnableVusionAddon}
              productEcommerceId={productEcommerceId}
              productLocationDescription={productLocationDescription}
              productLocationMap={productLocationMap}
            >
              <Button
                fontSize="subtitle2"
                size="lg"
                text={productDetailsMessages.findItOnShelf}
                type="button"
                variant={VH_VARIANTS.PRIMARY}
                onClick={() => MixpanelTracker.events.findMe(parsedProductInstanceData, null, mixpanelPositionContext)}
              />
            </FindProductPopup>
          </S.LargeCtaWrapper>
        )}
      </S.LargeProductContentContainer>
    </S.LargeProductDetailsContainer>
  );
};

export default LargeProductDetails;
