import { styled } from 'styled-components';

import { SCREEN_SIZE } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';

export const ResultPageContainer = styled(Flexbox)`
  position: relative;
  width: ${SCREEN_SIZE.desktopKiosk};
  background-color: var(--color-secondary-100);
`;

export const ScanSectionWrapper = styled.div`
  position: absolute;
  z-index: 9999;
  bottom: 10px;
  right: 0;
`;
