import { FC } from 'react';

import { IGroupedProduct } from '@app/kiosk/src/containers/SituationalResultContainer';

import { IParsedCharacter } from '@lib/core/characters/utils/parseCharacter';
import { TProductCategory } from '@lib/core/products/types';
import { TRetailerLocationStoreType } from '@lib/core/retailers/types';
import { localeKiosk } from '@lib/tools/locale/source/web/kiosk';

import { STATIC_COLORS } from '@components/web/src/foundations';
import { Flexbox } from '@components/web/src/foundations/Flexbox/Flexbox';
import { Text } from '@components/web/src/foundations/Text/Text';
import { KioskCirclesBackground } from '@components/web/src/kiosk/KioskCirclesBackground/KioskCirclesBackground';
import NoProductsAvailable from '@components/web/src/organisms/Catalog/NoProductsAvailable/NoProductsAvailable';
import { GroupedProducts } from '@components/web/src/organisms/GroupedProducts/GroupedProducts';
import * as S from '@components/web/src/templates/ResultPages/Kiosk/SituationalResultPage/styles';

interface IProps {
  productCategory: TProductCategory;
  isProductsLoading: boolean;
  groupedProducts: IGroupedProduct[];
  storeType: TRetailerLocationStoreType;
  charactersData: IParsedCharacter[];
  shouldHideRatingButton?: boolean;
  shouldHideShare?: boolean;
  shouldHideWishlist?: boolean;
  navigateToProductCatalogPage: (characterId: string) => void;
}

export const SituationalResultPage: FC<IProps> = ({
  groupedProducts,
  productCategory,
  isProductsLoading,
  storeType,
  charactersData,
  shouldHideRatingButton,
  shouldHideWishlist,
  shouldHideShare,
  navigateToProductCatalogPage,
}) => {
  const { SituationalPageTitleText, SituationalPageHeaderText } = localeKiosk.SituationalResultPageText;

  const showProductsSection = groupedProducts?.length > 0;

  return (
    <S.SituationalResultPageContainer isFullHeight direction="column" gap={0}>
      <KioskCirclesBackground />
      <S.HeaderWrapper isFullWidth direction="column" gap={0} padding="26px 32px">
        <Text color={STATIC_COLORS.base.black} size="body1" text={SituationalPageTitleText} />
        <Text
          color={STATIC_COLORS.productColors[productCategory][500]}
          fontFamily="Fraunces"
          size="h4"
          text={SituationalPageHeaderText}
          weight="semibold"
        />
      </S.HeaderWrapper>
      {showProductsSection ? (
        <Flexbox direction="column" gap={48} padding="40px 32px">
          {groupedProducts.map(({ characterId, products }, index) => {
            const characterData = charactersData.find(obj => obj.characterId === characterId);

            return (
              <GroupedProducts
                key={index}
                characterData={characterData}
                handleButtonClick={() => navigateToProductCatalogPage(characterId)}
                isProductsLoading={isProductsLoading}
                productCategory={productCategory}
                products={products}
                shouldHideRatingButton={shouldHideRatingButton}
                shouldHideShare={shouldHideShare}
                shouldHideWishlist={shouldHideWishlist}
                storeType={storeType}
              />
            );
          })}
        </Flexbox>
      ) : (
        <NoProductsAvailable />
      )}
    </S.SituationalResultPageContainer>
  );
};
