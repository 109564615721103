import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { GPRL_CHARACTER_QUERY } from '@lib/core/service/consts';
import { useApp } from '@lib/core/service/hooks';
import { useFidelityCard } from '@lib/core/users/hooks/useFidelityCard';
import { useUser } from '@lib/core/users/hooks/useUser';
import { useProductTip } from '@lib/tools/productTip/hooks/useProductTip';
import { fetchAndCreateNewProductTip, fetchProductTipData } from '@lib/tools/productTip/slices/';
import { FILTER_TYPE_ORDERING, ORDERING_TYPE_RANK, PRODUCT_CATEGORY_QUERY } from '@lib/tools/shared/helpers/consts';
import { useAddons, useEffectSkipFirst } from '@lib/tools/views/hooks';

const useProductTipData = () => {
  const dispatch = useDispatch();

  const { isEnableKioskFidelityScanAddon } = useAddons();
  const { userBestMatchCharacterForCurrentServiceProductCategory } = useUser();
  const { fidelityCardId } = useFidelityCard();
  const { locale, productCategory } = useApp();
  const { currentProductTipData, isCurrentProductTipLoading, usersProductTipsStorage } = useProductTip();

  const currentDate = moment().format('DD.MM.YYYY');
  const isProductTipFeatureActive = isEnableKioskFidelityScanAddon && fidelityCardId;

  const userCharacterId = userBestMatchCharacterForCurrentServiceProductCategory?.characterId;
  const savedProductTip = usersProductTipsStorage[fidelityCardId];
  const hasSavedProductTip = !!savedProductTip;
  const { lastProductTipDate = '', productTipCharacter = '', productTipId = '' } = savedProductTip || {};

  const isTipExpired = hasSavedProductTip && lastProductTipDate !== currentDate;
  const isCharacterChanged = hasSavedProductTip && productTipCharacter !== userCharacterId;

  const shouldFetchAndCreateNewProductTip =
    (!hasSavedProductTip || isCharacterChanged || isTipExpired) && userCharacterId;
  const shouldFetchProductTip = !currentProductTipData && hasSavedProductTip && productTipId;
  const shouldUpdateProductTipLocale = isProductTipFeatureActive && hasSavedProductTip && productTipId;

  useEffect(() => {
    if (isProductTipFeatureActive) {
      if (shouldFetchAndCreateNewProductTip) {
        /** Fetch products and create a new Product Tip if user:
         * Has a character and:
         * 1. Hasn't got any Product Tip yet
         * 2. Has a Product Tip in storage but:
         * * - updated character
         * * - tip date is expired (every day a new tip)
         */
        dispatch(
          fetchAndCreateNewProductTip({
            [FILTER_TYPE_ORDERING]: ORDERING_TYPE_RANK,
            [GPRL_CHARACTER_QUERY]: userCharacterId,
            [PRODUCT_CATEGORY_QUERY]: productCategory,
            offset: 0,
          }),
        );
      }

      if (shouldFetchProductTip) {
        /** Fetch data for currentProductTipData if user:
         * Has Product Tip in storage and:
         * - currentProductTipData for the Product Tip isn't loaded
         * - the Product Tip includes productTipId
         */
        dispatch(fetchProductTipData(productTipId));
      }
    }
  }, [fidelityCardId]);

  useEffectSkipFirst(() => {
    if (shouldUpdateProductTipLocale) {
      /** Fetch data for currentProductTipData according to the current locale
       */
      dispatch(fetchProductTipData(productTipId));
    }
  }, [locale]);

  return { currentProductTipData, isCurrentProductTipLoading };
};

export default useProductTipData;
